$animation-speed: 2s;

.loading-indicator {
    width: 100%;
    max-height: 100vh;

    svg {
        enable-background: new 0 0 48 47;

        .bubble-point {
            fill: url(#SVGID_1_);
        };
        
        .dot {
            fill: #09305F;
            fill-rule: evenodd;
            clip-rule: evenodd;
        };

        .dot-left {
            animation: bounce-left $animation-speed infinite ease-in-out;
        };

        .dot-middle {
            animation-delay: 1s;
            animation: bounce-mid $animation-speed infinite ease-in-out;
        };

        .dot-right {
            animation-delay: 2s;
            animation: bounce-right $animation-speed infinite ease-in-out;
        };

        .bubble-bottom-left stop {
            stop-color: #D8D8D8;
        };

        .bubble-bottom-left stop:nth-of-type(1) {
            stop-color: #FFFFFF;
        };

        .bubble-bottom-left stop:nth-last-of-type(2) {
            stop-color: #B9B9B9;
        };

        .bubble-top-right stop:nth-last-of-type(1) {
            // stop-color: #989898;
            stop-color: darken($color: #F1F1F1, $amount: 0%);
            animation: move-gradient-top-dark-to-light $animation-speed infinite ease-in-out;
        };

        .bubble-top-right stop:nth-last-of-type(2) {
            // stop-color: #AAAAAA;
            stop-color: darken($color: #F1F1F1, $amount: 50%);
            animation: move-gradient-top-light-to-dark $animation-speed infinite ease-in-out;
        };

        .bubble-top-right stop:nth-last-of-type(3) {
            stop-color: #D8D8D8;
        };

        .bubble-top-right stop:nth-last-of-type(4) {
            stop-color: #FFFFFF;
        };

        .bubble-top-right-path {
            fill: url(#SVGID_00000161623966746375175540000003042307788318822567_);
        };

    };

    @keyframes move-gradient-top-light-to-dark {
        0% {
            stop-color: darken($color: #F1F1F1, $amount: 0%);
        };

        50% {
            stop-color: darken($color: #F1F1F1, $amount: 50%);
        };

        100% {
            stop-color: darken($color: #F1F1F1, $amount: 0%);
        };
    };

    @keyframes move-gradient-top-dark-to-light {
        0% {
            stop-color: darken($color: #F1F1F1, $amount: 50%);
        };

        50% {
            stop-color: darken($color: #F1F1F1, $amount: 0%);
        };

        100% {
            stop-color: darken($color: #F1F1F1, $amount: 50%);
        };
    };

    @keyframes bounce-left {
        0% {
            fill: #09305F;
            transform: translateY(0px);
        };

        50% {
            fill: lighten($color: #09305F, $amount: 10%);
            transform: translateY(-10px);
        };

        80% {
            fill: #09305F;
            transform: translateY(0px);
        };
    };

    @keyframes bounce-mid {
        10% {
            fill: #09305F;
            transform: translateY(0px);
        };

        50% {
            fill: lighten($color: #09305F, $amount: 10%);
            transform: translateY(-10px);
        };

        90% {
            fill: #09305F;
            transform: translateY(0px);
        };
    };

    @keyframes bounce-right {
        20% {
            fill: #09305F;
            transform: translateY(0px);
        };

        50% {
            fill: lighten($color: #09305F, $amount: 10%);
            transform: translateY(-10px);
        };

        100% {
            fill: #09305F;
            transform: translateY(0px)
        };
    };

};